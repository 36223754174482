.home-container {
	width: 80%;
	margin: 0 auto;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
	display: block;
}
.account-wrapper span{
	font-size: 16px;
	color: white;
}
.login-form {
	width: 320px;
	height: 350px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	padding-bottom: 20px;
}
.login-form button {
	background: #FF742B;
}
.inp {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
}
.inp .label {
  position: absolute;
  top: 16px;
  left: 0;
  font-size: 16px;
  color: #9098a9;
  font-weight: 500;
  transform-origin: 0 0;
  transition: all 0.2s ease;
}
.inp .border {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: #07f;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: all 0.15s ease;
}
.inp input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 12px 0;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #c8ccd4;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
}
.inp input:hover {
  background: rgba(34,50,84,0.03);
}
.inp input:not(:placeholder-shown) + span {
  color: #5a667f;
  transform: translateY(-26px) scale(0.75);
}
.inp input:focus {
  background: none;
  outline: none;
}
.inp input:focus + span {
  color: #07f;
  transform: translateY(-26px) scale(0.75);
}
.inp input:focus + span + .border {
  transform: scaleX(1);
}
@media only screen and (max-width: 1200px) {
	.home-container {
		width: 100%;
		margin: 0 auto;
	}
}