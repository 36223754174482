footer, footer a {
	font-size: 13px;
	color: black;
}
footer {
	padding: 30px 0;
	text-align: center;
}
footer li {
	margin-left: 3px;
	margin-right: 3px;
}
footer {
	background: white;
	padding: 10px 0 30px 0;
}