.search-pagination {
	padding: 20px 0 0;
	text-align: center;
	background: transparent;
	text-align: center;
}
.search-pagination li {
	display: inline-block;
}
.search-pagination li button {
	margin: 3px;
	padding: 5px 10px;
	font-size: 12px;
	text-align: center;
	background: transparent;
	color: #9d9d9d;
	font-weight: normal;
	border: 1px solid #9d9d9d;
	outline: none;
}
.icon-ellipsis::before {
	content: "\e129";
}
.icon-fast-forward::before {
	content: "\e055";
}
.icon-rewind::before {
	content: "\e051";
}
.search-pagination li.active button {
	border-color: #FF742B;
	color: #FF742B;
}

.hide {
	display: none !important;
}

button, input[type="submit"] {
	-webkit-font-smoothing: antialiased;
	background-color: #477DCA;
	border-radius: 3px;
	color: white;
	display: inline-block;
	font-size: 1em;
	font-weight: bold;
	line-height: 1;
	padding: 0.75em 1em;
	text-decoration: none;
}
button, input[type="submit"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	border: none;
	cursor: pointer;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
}
button:disabled, input[type="submit"]:disabled {
	cursor: not-allowed;
	opacity: 0.5;
}