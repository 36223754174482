html, body {
	color: black;
	font-family: Verdana, Geneva, sans-serif !important;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul, ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
a {
  -webkit-transition: color 0.1s linear;
  -moz-transition: color 0.1s linear;
  transition: color 0.1s linear;
  color: #477DCA;
  text-decoration: none;
}