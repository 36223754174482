html, body {
	color: black;
	font-family: Verdana, Geneva, sans-serif !important;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul, ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
a {
  transition: color 0.1s linear;
  color: #477DCA;
  text-decoration: none;
}
input, label, select {
	display: block;
	font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
	font-size: 1em;
	line-height: 2em;
	color: #5C5C5C;
	background-color: white;
	box-shadow: none;
}
h1, h2, h3, h4, h5 {
	font-weight: 200;
	font-family: Verdana, Geneva, sans-serif !important;
}
@font-face {
  font-family: 'icomoon';
  src:  url('/assets/icomoon.woff') format('woff');
}
.page-header {
	position: fixed;
	z-index: 15;
	width: 80%;
	height: 60px;
	padding: 5px;
	background: #FF742B;
	border: none;
}
.page-header .logo-wrapper {
	float: left;
	width: 230px;
}
.page-header a, .page-header a:visited {
	color: white;
}

.page-header .logo-wrapper .logo img {
	width: 48px;
	height: 48px;
}
.logo img {
	float: left;
	height: 60px;
	margin-right: 10px;
	transition: all 0.5s ease;
}
.page-header .logo-wrapper .logo-name {
	color: white;
	font-size: 18px;
	padding-top: 6px;
}
.logo .logo-name {
	float: left;
	padding-top: 8px;
	line-height: 1em;
	font-size: 22px;
	color: #9d9d9d;
}
.page-header .search-wrapper {
	position: relative;
	float: left;
	width: calc(90% - 230px);
	margin-top: 3px;
}
.search-wrapper .input-item-wrapper {
	display: inline-block;
	width: calc(100% - 100px);
	margin-right: 5px;
}
button::-moz-focus-inner, input::-moz-focus-inner {
	border: 0;
	padding: 0;
}
.search-wrapper input::-webkit-input-placeholder {
	color: #ccc;
	font-size: 14px;
	font-weight: 300;
}
.search-wrapper input::-ms-input-placeholder {
	color: #ccc;
	font-size: 14px;
	font-weight: 300;
}
.search-wrapper input::placeholder {
	color: #ccc;
	font-size: 14px;
	font-weight: 300;
}
.page-header .search-wrapper input {
	border-radius: 0;
	border-color: transparent;
	border: none;
	width: 100%;
}
.search-wrapper input {
	border-radius: 25px;
	box-shadow: none;
	line-height: 25px;
	padding: 0 15px 0 50px;
	height: 42px;
	border-color: #b6b6b6;
	color: #5c5c5c;
}
input[type="search"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
}
.icon-search::before {
	content: "\E036";
}
.search-wrapper .icon-search {
	position: absolute;
	top: 8px;
	left: 14px;
	color: #FF742B;
	font-size: 24px;
}
[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.search-wrapper .powered-by {
	width: 80px;
	font-size: 12px;
	display: inline-block;
	position: relative;
}
.search-wrapper .powered-by img {
	width: 60px;
	position: relative;
	top: -2px;
	left: 4px;
	vertical-align: bottom;
}
.icon-head::before {
	content: "\E074";
}
.icon-head {
	color: white;
}
.page-header .account-wrapper {
	position: absolute;
	top: 15px;
	right: 20px;
	font-size: 24px;
}


@media only screen and (max-width: 1200px) {
	.page-header {
		width: 100%;
	}
}
@media only screen and (max-width: 750px) {
	.logo-name {
		display: none;
	}
	.page-header .logo-wrapper {
	    width: 50px;
	}
	.page-header .search-wrapper {
		width: calc(90% - 50px);
	}
}
@media only screen and (max-width: 450px) {
	.page-header .powered-by {
		display: none;
	}
	.page-header .search-wrapper .input-item-wrapper {
		width: calc(100% - 10px);
	}
}
.search-results .item {
	border-bottom: solid 1px #eee;
	position: relative;
	padding: 0;
}
.main .search-results .item {
	position: relative;
	padding: 3px 15px 10px;
	overflow: hidden;
}
.main .search-results .item .item-main {
	width: 100%;
}
.item-main {
	display: table;
}
.item-content-wrapper {
	width: 100%;
	display: table-cell;
	vertical-align: middle;
}
.main .search-results .item .item-main .item-content-wrapper {
	padding: 0 !important;
}
.item-title-and-infos {
	float: left;
	padding-top: 5px;
	width: calc(100% - 70px);
}
.main h2 {
	display: inline-block;
	margin-top: 1em;
	font-size: 20px;
	color: #5C5C5C;
}
.item-main h2 {
	float: left;
	margin-top: 0;
	font-size: 16px;
	font-weight: normal;
}
.main .search-results .item .item-main .item-title-and-infos h2 {
	font-size: 14px;
	margin-bottom: 4px;
}
.main .search-results .item .item-main .item-title-and-infos h2, .main .search-results .item .item-main .item-title-and-infos .item-infos {
	float: inherit;
	display: block;
}
.main .search-results .item .item-main .item-title-and-infos {
	float: inherit;
}
.item-main h2 a {
	color: #5C5C5C;
}
.main .search-results .item .item-main .item-title-and-infos h2 a {
	color: black;
	text-decoration: none;
}
ul.list-inline, ol.list-inline {
	display: inline-block;
}
.item-infos {
	float: left;
	clear: both;
}
.main .search-results .item .item-main .item-title-and-infos .item-infos {
	line-height: 12px;
	white-space: nowrap;
}
.main .search-results .item .item-main .item-title-and-infos h2, .main .search-results .item .item-main .item-title-and-infos .item-infos {
	float: inherit;
	display: block;
}
.main .search-results .item .item-main .item-title-and-infos .item-infos > li:first-child {
	padding-left: 0;
	border: none;
}
.main .search-results .item .item-main .item-title-and-infos .item-infos > li {
	margin-right: 2px;
	border-left: 1px solid #cfcfcf;
	padding-left: 5px;
}
.main .search-results .item .item-main .item-title-and-infos .item-infos i, .main .search-results .item .item-main .item-title-and-infos .item-infos span, .main .search-results .item .item-main .item-title-and-infos .item-infos a {
	font-size: 10px;
	color: dimgray;
}
ul.list-inline li, ol.list-inline li {
	display: inline-block;
}
.item-infos a:hover {
	border-bottom: dotted 1px #ccc;
}
a:active, a:hover {
	outline: 0;
}
.main .search-results .item .item-main .text-container {
	padding: 10px 0 0 10px;
	max-width: 800px;
	color: black;
}
.text-container p {
	margin-bottom: 5px;
}
.text-container {
	font-size: 12px;
	color: #767676;
}
.main .search-results {
	margin-top: 42px;
	padding-top: 10px;
}
.search-pagination {
	padding: 20px 0 0;
	text-align: center;
	background: transparent;
	text-align: center;
}
.search-pagination li {
	display: inline-block;
}
.search-pagination li button {
	margin: 3px;
	padding: 5px 10px;
	font-size: 12px;
	text-align: center;
	background: transparent;
	color: #9d9d9d;
	font-weight: normal;
	border: 1px solid #9d9d9d;
	outline: none;
}
.icon-ellipsis::before {
	content: "\E129";
}
.icon-fast-forward::before {
	content: "\E055";
}
.icon-rewind::before {
	content: "\E051";
}
.search-pagination li.active button {
	border-color: #FF742B;
	color: #FF742B;
}

.hide {
	display: none !important;
}

button, input[type="submit"] {
	-webkit-font-smoothing: antialiased;
	background-color: #477DCA;
	border-radius: 3px;
	color: white;
	display: inline-block;
	font-size: 1em;
	font-weight: bold;
	line-height: 1;
	padding: 0.75em 1em;
	text-decoration: none;
}
button, input[type="submit"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	border: none;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	vertical-align: middle;
	white-space: nowrap;
}
button:disabled, input[type="submit"]:disabled {
	cursor: not-allowed;
	opacity: 0.5;
}
.main header {
	border-bottom: solid 1px #eee;
	line-height: 77px;
	padding: 0 30px;
	background-color: #fdfdfd;
	white-space: nowrap;
}

.main header {
	border: none;
	padding-left: 10px;
	font-size: 12px;
	position: fixed;
	line-height: 42px;
	top: 60px;
	z-index: 5;
	height: 42px;
}
.main header {
	padding: 0 10px !important;
	width: 100%;
	left: 0;
}

.wrap-dd-select:hover {
	box-shadow: inset 0 -2px 0 rgba(0,0,0,0.05),0 0 5px rgba(0,0,0,0.06);
}
.wrap-dd-select {
	display: inline-block;
	position: relative;
	font-size: 13px;
	line-height: 32px;
	text-align: left;
	padding: 0 20px 0 8px;
	margin: 0 8px 0 2px;
	border: solid 1px #c3c3c3;
	border-radius: 3px;
	cursor: pointer;
	outline: none;
	color: #5C5C5C;
	background-color: white;
	z-index: 3;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.wrap-dd-select {
	line-height: 24px;
}
.wrap-dd-select::after {
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	top: 48%;
	right: 6px;
	height: 0;
	width: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #9d9d9d;
}
.wrap-dd-select .dropdown::after {
	content: "";
	width: 0;
	height: 0;
	position: absolute;
	bottom: 100%;
	right: 15px;
	border-width: 0 6px 6px 6px;
	border-style: solid;
	border-color: #fff transparent;
}
.wrap-dd-select .dropdown::before {
	content: "";
	width: 0;
	height: 0;
	position: absolute;
	bottom: 100%;
	right: 13px;
	border-width: 0 8px 8px 8px;
	border-style: solid;
	border-color: #9d9d9d transparent;
}
.wrap-dd-select.active .dropdown {
	opacity: 1;
	pointer-events: auto;
}
.wrap-dd-select .dropdown {
	position: absolute;
	top: 30px;
	right: -10px;
	z-index: 10;
	background: white;
	padding: 0;
	border-radius: 5px;
	border: 1px solid #9d9d9d;
	box-shadow: 0 0 5px rgba(0,0,0,0.1);
	font-weight: normal;
	list-style: none;
	opacity: 0;
	pointer-events: none;
	transition: all 0.1s ease-in;
}
.wrap-dd-select .dropdown li:first-of-type i {
	border-radius: 5px 5px 0 0;
}
.wrap-dd-select .dropdown li:last-of-type i {
	border-radius: 0 0 5px 5px;
	border: none;
}
.wrap-dd-select .dropdown li i {
	display: block;
	padding: 4px 12px;
	text-decoration: none;
	color: #5C5C5C;
	border-bottom: 1px solid #e6e8ea;
	transition: all 0.3s ease-out;
}
.main .search-infos {
	position: absolute;
	top: 0px;
	right: 20px;
	font-size: 13px;
	z-index: 1;
}

@media only screen and (min-width: 1200px) {
	.main header {
		left: 10%;
		right: 10%;
		width: unset;
	}
}
@media only screen and (max-width: 750px) {
	.main .search-infos {
		display: none;
	}
}
.main {
	font-size: 13px;
	position: relative;
	padding: 60px 0 0 0;
	background: #f6f6ef;
}
.search-pagination {
	padding: 20px 0 0;
	text-align: center;
	background: transparent;
}
footer, footer a {
	font-size: 13px;
	color: black;
}
footer {
	padding: 30px 0;
	text-align: center;
}
footer li {
	margin-left: 3px;
	margin-right: 3px;
}
footer {
	background: white;
	padding: 10px 0 30px 0;
}
.home-container {
	width: 80%;
	margin: 0 auto;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
	display: block;
}
.account-wrapper span{
	font-size: 16px;
	color: white;
}
.login-form {
	width: 320px;
	height: 350px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding-bottom: 20px;
}
.login-form button {
	background: #FF742B;
}
.inp {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
}
.inp .label {
  position: absolute;
  top: 16px;
  left: 0;
  font-size: 16px;
  color: #9098a9;
  font-weight: 500;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  transition: all 0.2s ease;
}
.inp .border {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: #07f;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  transition: all 0.15s ease;
}
.inp input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 12px 0;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #c8ccd4;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
}
.inp input:hover {
  background: rgba(34,50,84,0.03);
}
.inp input:not(:placeholder-shown) + span {
  color: #5a667f;
  -webkit-transform: translateY(-26px) scale(0.75);
          transform: translateY(-26px) scale(0.75);
}
.inp input:focus {
  background: none;
  outline: none;
}
.inp input:focus + span {
  color: #07f;
  -webkit-transform: translateY(-26px) scale(0.75);
          transform: translateY(-26px) scale(0.75);
}
.inp input:focus + span + .border {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
@media only screen and (max-width: 1200px) {
	.home-container {
		width: 100%;
		margin: 0 auto;
	}
}
